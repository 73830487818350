import { PORT_AUTH_KEY } from "../constants/common";
import { post, get, put, deletee } from "./index";

export const getAllPortfolioWithoutLogin = (params) => {
  const request = {
    subUrl: `/portfoliosignup`,
    params,
    ad_token: PORT_AUTH_KEY,
  };
  // delete request["authAccessToken"];
  // delete request?.params["authAccessToken"];

  return get(request);
};

export const getAllPortfolio = (params) => {
  const request = {
    subUrl: `/portfolioListing`,
    params,
  };

  return get(request);
};
export const getAllPortfolioByUser = (params) => {
  const request = {
    subUrl: `/portfoliobyuser`,
    params,
  };

  return get(request);
};

export const getPortfolioByID = (id) => {
  const request = {
    subUrl: `/portfolio/${id}`,
  };
  return get(request);
};

export const addPortfolio = (data) => {
  const request = {
    subUrl: `/portfolio`,
    data,
    isJSON: true,
  };
  return post(request);
};

export const updatePortfolio = (data, id) => {
  const request = {
    subUrl: `/portfolio/${id}`,
    data: data,
    isJSON: true,
  };
  return put(request);
};

export const deletePortfolio = (id) => {
  const request = {
    subUrl: `/portfolio/${id}`,
  };
  return deletee(request);
};
