import { listingReducer } from "../reducers/utils/listingReducer";
import { getAllUsers } from "../../services/users";

const { reducer, actions, types } = listingReducer({
  name: `users`,
  getApi: getAllUsers,
});

const users = reducer;
export { users, actions, types };
