export const spaceRegex = /^(?=.*\S).+$/;
export const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[!@#$%^&?()-_+{}|:;"'*])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
export const DATE_FORMAT = "YYYY-MM-DD";
export const ONLY_IMAGES = "image/png, image/gif, image/jpeg";

// Real ID
export const MS_AD_CLEINT_ID = "1a662703-ae55-4f56-ad2b-191e65461ae0";
export const MS_AD_TENANT_ID = "c9b9cb50-3644-4db4-a267-fa84df2f4ceb";

// Testing 
// export const MS_AD_CLEINT_ID = "07ca598f-cc81-4797-99a4-73c704eebea5";
// export const MS_AD_TENANT_ID = "9f939d94-0007-42c6-b87d-0a52cf98b86c";
export const MS_AD_REDIRECT_URI =
  "https://foundation-contentpreprod.mtn.ng/callback/";
export const MS_AD_POST_SIGNOUT_URI =
  "https://foundation-contentpreprod.mtn.ng";

export const ALLOWED_FILE_TYPES = `audio/*,image/*,video/*,application/xlsx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel`;

export const PORT_AUTH_KEY =
  "145e4653ef8c6ff2ef4b2d6cb80493ca923bf1e868b97cf68464edc697b16399";

export const ALLOWED_ALL_FILE_TYPES = [
  "jpg",
  "gif",
  "bmp",
  "png",
  "jpeg",
  "m4v",
  "avi",
  "mpg",
  "mp4",
  "mov",
  "flv",
  "wmv",
  "doc",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "pdf",
  "docx",
  "txt",
  "m4a",
  "mp3",
  "wav",
];

export const MEDIA_PARTNERS = [
  {
    id: "101",
    name: "PHD",
  },
  {
    id: "102",
    name: "YBR (Yellow Brick Road)",
  },
  {
    id: "103",
    name: "IBST Media",
  },
  {
    id: "104",
    name: "BHM (Black House Media)",
  },
  {
    id: "105",
    name: "Play House Media",
  },
];

export const CONTENT_TYPES = ["Audio", "videos", "Documents", "Images"];

export const ANTD_MEDIA_PARTNERS = [
  {
    value: "101",
    label: "PHD",
  },
  {
    value: "102",
    label: "YBR (Yellow Brick Road)",
  },
  {
    value: "103",
    label: "IBST Media",
  },
  {
    value: "104",
    label: "BHM (Black House Media)",
  },
  {
    value: "105",
    label: "Play House Media",
  },
];
