export const appRoutesConst = {
  // Home
  index: "/",

  // Session Management
  forgotPassword: "/forgot-pass",
  resetMediaPassword: "/reset-media-pass",
  loginCms: "/login-cms",
  login: "/login",
  adCallback: "/callback",
  autoLogout: "/autoLogout",
  verifyOTP: "/verifyOTP",


  //Admin
  adminHome: "/admin/home",
  createUser: "/admin/create-user",
  editUser: "/admin/edit-user",
  adminViewUsers: "/admin/view-user",
  adminUpload: "/admin/upload",
  adminViewGroup: "/admin/viewgroup",

  //Portfolio
  portfolio: "/admin/portfolio",
  createPortfolio: "/admin/create-portfolio",
  editPortfolio: "/admin/edit-portfolio",

  // Project
  createProject: "/admin/create-project",
  viewProject: "/admin/view-project",
  editProject: "/admin/edit-project",

  //Uploads
  uploads: "/admin/upload",
  uploadDetails: "/admin/upload-details/:id",
  createUploads: "/admin/createuploads",
  multipleUploads: "/admin/create-multipleuploads",
  editUploads: "/admin/editUploads",

  //Media Partner
  viewMediaPartner: "/admin/view-mediapartner",
  createMediaPartner: "/admin/create-mediapartner",
  editMediaPartner: "/admin/edit-mediapartner",

  //Permissions
  viewPermissions: "/admin/view-permission",
  addPermissions: "/admin/add-permission",

  //Settings
  profile: "/admin/profile",
  resetPassword: "/admin/reset-password",
  editProfile: "/admin/edit-profile",
};
