import { get } from "lodash";
import { Notify as toast } from "notiflix/build/notiflix-notify-aio";
import { responseHeaderKeys } from "../services";

export const errorToast = ({ content, options = {} }) => {
  if (!content) {
    return;
  }
  return toast.failure(content, {
    timeout: 3000,
    showOnlyTheLastOne: true,
    clickToClose: true,
    position: "right-top",
  });
};

export const getExtraDataFromListing = (response) => {
  const responseData = response.data;
  const extraData = {};
  for (const key in responseData) {
    if (key !== "data") {
      extraData[key] = responseData[key];
    }
  }
  return extraData;
};

export const successToast = ({ content, options = {} }) => {
  if (!content) {
    return;
  }
  return toast.success(content, {
    timeout: 3000,
    showOnlyTheLastOne: true,
    clickToClose: true,
    position: "right-top",
  });
};

export const infoToast = ({ content, options = {} }) => {
  if (!content) {
    return;
  }
  return toast(content, {
    type: "info",
    ...options,
  });
};

export const warningToast = ({ content, options = {} }) => {
  if (!content) {
    return;
  }
  return toast.warning(content, {
    timeout: 3000,
    showOnlyTheLastOne: true,
    clickToClose: true,
    position: "right-top",
  });
};

export function getIntial(text) {
  let full_name = text.split(" ");
  let initials = full_name[0][0];
  if (text) {
    if (full_name.length >= 2 && full_name[1]) {
      initials += full_name[1][0];
    }
  } else {
    initials = "";
  }
  return initials;
}

export const getFileExtension = (filename) => {
  // get file extension
  const extension = filename.split(".").pop();
  return extension;
};
export const getErrorObject = (error) => {
  let message = "Error!";

  if (error && error.data && error.data) {
    // API server error message

    if (error.data.error) {
      Object.keys(error.data.error).map((key) => {
        console.log("errororor get", error.data.error[key]);
        message = error.data.error[key];
        return null;
      });
    } else if (error.data.message) {
      message = error.data.message;
    }
  } else if (error && error.message) {
    // js error message
    message = error.message;
  }
  return {
    message,
  };
};

export const normalizeByGroup = ({ data, key, groupKey }) => {
  let obj = {};
  let ids = [];
  let group = {};
  let groupIds = new Set();
  data.forEach((element) => {
    obj[element[key]] = element;
    groupIds.add(element[groupKey]);
    if (!group[element[groupKey]]) {
      group[element[groupKey]] = [];
    }
    group[element[groupKey]].push(element[key]);
    ids.push(element[key]);
  });
  return { data: obj, ids, groupIds: [...groupIds], group };
};

export const currencies = {
  inr: { key: "inr", symbol: "₹" },
  euro: { key: "euro", symbol: "€" },
  pound: { key: "pound", symbol: "£" },
};
const normalize = ({ data, key = "id" }) => {
  // let obj = {};
  // let ids = [];
  // data &&
  //   data.length > 0 &&
  //   data.forEach((element, i) => {
  //     if (element[key]) {
  //       obj[element[key]] = element;
  //       ids.push(element[key]);
  //     } else {
  //       obj[i] = element;
  //       ids.push(i);
  //     }
  //   });
  return { data: data };
};
export const normalizeResponseWithPagination = ({ response, key = "id" }) => {
  const dataArr = response.data ? response.data : [];
  console.log("normalizeResponseWithPagination", dataArr);

  //Current workaround
  let resPagination = {};
  if (dataArr.total_pages) {
    resPagination = {
      total: dataArr.total_record,
      total_pages: dataArr.total_pages,
      previous_page: dataArr.prv_pages,
      next_page: dataArr.next_pages,
    };
  } else {
    resPagination = {
      total: 0,
      total_pages: 0,
      previous_page: null,
      next_page: null,
    };
  }

  const { data, ids } = normalize({ data: dataArr, key });

  const { total, total_pages, previous_page, next_page } = resPagination;

  return {
    data,
    ids,
    pagination: { total, total_pages, previous_page, next_page },
  };
};

function verifyObject(data, params, defaultValue) {
  return get(data, params, defaultValue);
}

export const isImage = (filename) => {
  var ext = getFileExtension(filename);
  switch (ext.toLowerCase()) {
    case "jpg":
    case "gif":
    case "bmp":
    case "png":
    case "jpeg":
      //etc
      return "Image";
    default:
  }
  return false;
};

export const isVideo = (filename) => {
  var ext = getFileExtension(filename);
  switch (ext.toLowerCase()) {
    case "m4v":
    case "avi":
    case "mpg":
    case "mp4":
    case "mov":
    case "flv":
    case "wmv":
      return "Video";
    default:
  }
  return false;
};

export const isAudio = (filename) => {
  var ext = getFileExtension(filename);
  switch (ext.toLowerCase()) {
    case "m4a":
    case "mp3":
    case "ogg":
      return "Audio";
    default:
  }
  return false;
};

export const isDocument = (filename) => {
  var ext = getFileExtension(filename);
  switch (ext.toLowerCase()) {
    case "doc":
    case "xls":
    case "xlsx":
    case "pdf":
    case "docx":
    case "txt":
      return "Document";
    default:
  }
  return false;
};

export { verifyObject };
