import { post, get, put, deletee } from "./index";

export const getAllProjects = (params) => {
  const request = {
    subUrl: `/projectListing`,
    params,
  };
  return get(request);
};

export const getProjectByID = (id) => {
  const request = {
    subUrl: `/project/${id}`,
  };
  return get(request);
};

export const addProject = (data) => {
  const request = {
    subUrl: `/project`,
    data,
    isJSON: true,
  };
  return post(request);
};

export const updateProject = (data, id) => {
  const request = {
    subUrl: `/project/${id}`,
    data,
    isJSON: true,
  };
  return put(request);
};

export const deleteProject = (id) => {
  const request = {
    subUrl: `/project/${id}`,
    isJSON: true,
  };
  return deletee(request);
};
