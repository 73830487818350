import { lazy } from "react";

import { appRoutesConst } from "./navigation";
// import AutoLogout from "../containers/AutoLogout";
// import Createportfolio from "./containers/Admin/CreatePortfolio";
// import Portfolio from "./containers/Admin/Portfolio";

// Home
const Home = lazy(() => import("../containers/Admin/Home"));

//Users
const ViewUsers = lazy(() => import("../containers/Admin/Users/ViewUsers"));
const CreatUsers = lazy(() => import("../containers/Admin/Users/CreateUser"));
const EditUser = lazy(() => import("../containers/Admin/Users/EditUser"));

// Portfolio
const ViewPortfolio = lazy(() =>
  import("../containers/Admin/Portfolio/Portfolio")
);
const CreatePortfolio = lazy(() =>
  import("../containers/Admin/Portfolio/CreatePortfolio")
);
const EditPortfolio = lazy(() =>
  import("../containers/Admin/Portfolio/EditPortfolio")
);

// Projects
const AdminCreateProject = lazy(() =>
  import("../containers/Admin/Projects/Createproject")
);
const Editproject = lazy(() =>
  import("../containers/Admin/Projects/EditProject")
);
const Viewproject = lazy(() =>
  import("../containers/Admin/Projects/ViewProject")
);

// Uploads
const AdminUploads = lazy(() => import("../containers/Admin/Uploads/Uploads"));
const UploadsDetails = lazy(() =>
  import("../containers/Admin/Uploads/Uploaddetails")
);
const CreateUploads = lazy(() =>
  import("../containers/Admin/Uploads/Createuploads")
);
const MultipleUploads = lazy(() =>
  import("../containers/Admin/Uploads/MultipleUploads")
);
const EditUploads = lazy(() =>
  import("../containers/Admin/Uploads/EditUpload")
);

// Uploads
const ViewMediaPartner = lazy(() =>
  import("../containers/Admin/MediaPartner/ViewMediaPartner")
);
const CreateMediaPartner = lazy(() =>
  import("../containers/Admin/MediaPartner/MediaPartnerCreate")
);
const EditMediaPartner = lazy(() =>
  import("../containers/Admin/MediaPartner/EditMediaPartner")
);

// Permissions
const ViewPermissions = lazy(() =>
  import("../containers/Admin/Permissions/ViewPermissions")
);
const CreatePermissions = lazy(() =>
  import("../containers/Admin/Permissions/CreatePermissions")
);

// Settings
const Profile = lazy(() => import("../containers/Admin/Settings/Profile"));
const EditProfile = lazy(() =>
  import("../containers/Admin/Settings/EditProfile")
);

const ResetPassword = lazy(() =>
  import("../containers/Admin/Settings/Resetpass")
);

//Landing Page
const Landing = lazy(() => import("../containers/Landing/Landing"));

//Session Management
const LoginForm = lazy(() => import("../containers/Login/LoginForm"));
const LoginCMS = lazy(() => import("../containers/Login/Login-cms"));
const ForgotPassword = lazy(() => import("../containers/Login/Forgotpass"));
const AdCallback = lazy(() => import("../containers/Login/AdCallBack"));
const AutoLogout = lazy(() => import("../containers/AutoLogout"));
const OTPForLogin = lazy(() => import("../containers/Login/OTPForLogin"));

const OTPResetPassword = lazy(() =>
  import("../containers/Login/OTPResetPassword")
);

export const routesArray = [
  // session management
  {
    path: appRoutesConst.login,
    element: LoginForm,
    isRestrictedRoute: true,
  },
  {
    path: appRoutesConst.resetMediaPassword,
    element: OTPResetPassword,
    isRestrictedRoute: true,
  },
  {
    path: appRoutesConst.verifyOTP,
    element: OTPForLogin,
    isRestrictedRoute: true,
  },
  {
    path: appRoutesConst.loginCms,
    element: LoginCMS,
    isRestrictedRoute: true,
  },
  {
    path: appRoutesConst.adCallback,
    element: AdCallback,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.forgotPassword,
    element: ForgotPassword,
    isRestrictedRoute: true,
  },
  {
    path: appRoutesConst.autoLogout,
    element: AutoLogout,
    isOpenRoute: true,
  },

  // landing page
  {
    path: appRoutesConst.index,
    element: Landing,
    isRestrictedRoute: true,
  },

  // admin Home
  {
    path: appRoutesConst.adminHome,
    element: Home,
    isPrivateRoute: true,
  },

  // Users
  {
    path: appRoutesConst.adminViewUsers,
    element: ViewUsers,
    isPrivateRoute: true,
  },
  {
    path: appRoutesConst.createUser,
    element: CreatUsers,
    isPrivateRoute: true,
  },
  {
    path: appRoutesConst.editUser,
    element: EditUser,
    isPrivateRoute: true,
  },

  // Portfolio
  {
    path: appRoutesConst.portfolio,
    element: ViewPortfolio,
    isPrivateRoute: true,
  },
  {
    path: appRoutesConst.createPortfolio,
    element: CreatePortfolio,
    isPrivateRoute: true,
  },
  {
    path: appRoutesConst.editPortfolio,
    element: EditPortfolio,
    isPrivateRoute: true,
  },

  // Projects
  {
    path: appRoutesConst.viewProject,
    element: Viewproject,
    isPrivateRoute: true,
  },
  {
    path: appRoutesConst.createProject,
    element: AdminCreateProject,
    isPrivateRoute: true,
  },
  {
    path: appRoutesConst.editProject,
    element: Editproject,
    isPrivateRoute: true,
  },

  // Uploads
  {
    path: appRoutesConst.uploads,
    element: AdminUploads,
    isPrivateRoute: true,
  },
  {
    path: appRoutesConst.uploadDetails,
    element: UploadsDetails,
    isPrivateRoute: true,
  },
  {
    path: appRoutesConst.createUploads,
    element: CreateUploads,
    isPrivateRoute: true,
  },
  {
    path: appRoutesConst.multipleUploads,
    element: MultipleUploads,
    isPrivateRoute: true,
  },
  {
    path: appRoutesConst.editUploads,
    element: EditUploads,
    isPrivateRoute: true,
  },

  // Media Partnter
  {
    path: appRoutesConst.viewMediaPartner,
    element: ViewMediaPartner,
    isPrivateRoute: true,
  },
  {
    path: appRoutesConst.createMediaPartner,
    element: CreateMediaPartner,
    isPrivateRoute: true,
  },
  {
    path: appRoutesConst.editMediaPartner,
    element: EditMediaPartner,
    isPrivateRoute: true,
  },

  // Permissions
  {
    path: appRoutesConst.viewPermissions,
    element: ViewPermissions,
    isPrivateRoute: true,
  },
  {
    path: appRoutesConst.addPermissions,
    element: CreatePermissions,
    isPrivateRoute: true,
  },

  // Settings
  {
    path: appRoutesConst.profile,
    element: Profile,
    isPrivateRoute: true,
  },
  {
    path: appRoutesConst.editProfile,
    element: EditProfile,
    isPrivateRoute: true,
  },
  {
    path: appRoutesConst.resetPassword,
    element: ResetPassword,
    isPrivateRoute: true,
  },
];
