import { MS_AD_CLEINT_ID, MS_AD_TENANT_ID } from "./constants/common";
import { LogLevel } from "@azure/msal-browser";
import { errorToast } from "./utilities/utilities";
export const msalConfig = {
  auth: {
    clientId: MS_AD_CLEINT_ID,
    authority: `https://login.microsoftonline.com/${MS_AD_TENANT_ID}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri:
      process.env.NODE_ENV === "development"
        ? "http://localhost:4000"
        : "https://foundation-contentpreprod.mtn.ng/callback/",
    postLogoutRedirectUri:
      process.env.NODE_ENV === "development"
        ? "http://localhost:4000"
        : "https://foundation-contentpreprod.mtn.ng",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            // window.location.reload();
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me",
};
