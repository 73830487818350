import { combineReducers } from "redux";
// import { createBrowserHistory } from "history";
import {
  localStore,
  session,
  users,
  portfolios,
  projects,
  mediaPartners,
  uploads,
  permissions,
  uploadProgress,
} from "./reducers/Index";

const appReducer = combineReducers({
  localStore,
  session,
  users,
  portfolios,
  projects,
  mediaPartners,
  uploads,
  permissions,
  uploadProgress,
});

const rootReducers = (state, action) => {
  return appReducer(state, action);
};
export default rootReducers;
