import { post, get, put, deletee } from "./index";

export const getAllMedia = (params) => {
  const request = {
    subUrl: `/medialisting`,
    params,
  };
  return get(request);
};

export const getMediaByID = (id) => {
  const request = {
    subUrl: `/media/${id}`,
  };
  return get(request);
};

export const addMedia = (data) => {
  const request = {
    subUrl: `/media`,
    data,
    isJSON: true,
  };
  return post(request);
};

export const updateMedia = (data, id) => {
  const request = {
    subUrl: `/media/${id}`,
    data: data,
    isJSON: true,
  };
  return put(request);
};

export const deleteMedia = (id) => {
  const request = {
    subUrl: `/media/${id}`,
  };
  return deletee(request);
};
