import React from "react";
import { ColorRing } from "react-loader-spinner";

export default function PageLoader({ classProps }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
		alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
      className={`page-loader ${classProps || ""}`}
    >
      <ColorRing
        visible={true}
        height="100"
        width="100"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#fc0", "#fc0", "#fc0", "#fc0", "#fc0", "#fc0"]}
      />
    </div>
  );
}
