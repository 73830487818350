import { listingReducer } from "../reducers/utils/listingReducer";
import { getAllProjects } from "../../services/projects";

const { reducer, actions, types } = listingReducer({
  name: `projects`,
  getApi: getAllProjects,
});

const projects = reducer;
export { projects, actions, types };
