import { listingReducer } from "../reducers/utils/listingReducer";
import { getAllUploads } from "../../services/uploads";
const initialState = {
  uploadProgress: null,
  resetEmail: null,
};
const uploadProgress = (state = initialState, action) => {
  switch (action.type) {
    case "SET_UPLOAD_PROGRESS": {
      return {
        ...state,
        uploadProgress: action.payload,
      };
    }



    default:
      return state;
  }
};
const { reducer, actions, types } = listingReducer({
  name: `uploads`,
  getApi: getAllUploads,
});

const uploads = reducer;
export { uploads, uploadProgress, actions, types };
