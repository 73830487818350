import { post, get, put, deletee } from "./index";

export const getAllUsers = (params) => {
  const request = {
    subUrl: `/userListing`,
    params,
  };
  return get(request);
};

export const getUserByID = (id) => {
  const request = {
    subUrl: `/getuserbyid/${id}`,
  };
  return get(request);
};

export const saveAPILogs = (data) => {
  const request = {
    subUrl: `/logger`,
    data,
    isJSON: true,
  };
  return post(request);
};

export const addUser = (data) => {
  const request = {
    subUrl: `/register`,
    data,
    isJSON: true,
  };
  return post(request);
};

export const signupUser = (data, loginType, adToken) => {
  let adTokenParams = {};
  if (loginType !== "media") {
    adTokenParams = {
      ad_token: adToken,
    };
  }
  const request = {
    subUrl: loginType === "media" ? `/media-signup` : `/signup`,
    data,
    isJSON: true,
    ...adTokenParams,
  };
  return post(request);
};

export const mediaSignupUser = (data) => {
  const request = {
    subUrl: `/media-signup`,
    data,
    isJSON: true,
  };
  return post(request);
};

export const forgotPassword = (data) => {
  const request = {
    subUrl: `/forgot`,
    data,
    isJSON: true,
  };
  return post(request);
};

export const sendOTPAPI = (data) => {
  const request = {
    subUrl: `/verifylogin`,
    data,
    isJSON: true,
  };
  return post(request);
};

export const verifyOTPAPI = (data) => {
  const request = {
    subUrl: `/verifyotp`,
    data,
    isJSON: true,
  };
  return post(request);
};

export const resetPasswordViaOTP = (data, accessToken) => {
  const request = {
    subUrl: `/restforgotpwd`,
    data,
    isJSON: true,
    ad_token: accessToken,
  };
  return post(request);
};

export const updateUser = (data) => {
  const request = {
    subUrl: `/useredit/${data.id}`,
    data: data,
    isJSON: true,
  };
  return put(request);
};

export const deleteUser = (id) => {
  const request = {
    subUrl: `/user/${id}`,
  };
  return deletee(request);
};

export const updateUserStatus = (data) => {
  const request = {
    subUrl: `/userstatus`,
    data,
  };
  return post(request);
};

export const updateUserProfile = (data) => {
  const request = {
    subUrl: `/profile_update/${data.id}`,
    data,
    isJSON: true,
  };
  return put(request);
};

export const updateUserProfileImage = (data) => {
  const request = {
    subUrl: `/profileimage`,
    data,
  };
  return post(request);
};

export const resetPassword = (data) => {
  const request = {
    subUrl: `/pwd_update/${data.id}`,
    data,
    isJSON: true,
  };
  return put(request);
};

export const assignUserPermission = (data, id) => {
  const request = {
    subUrl: `/usr_permission/${id}`,
    data,
    isJSON: true,
  };
  return put(request);
};
