import React from "react";
import { Button } from 'react-bootstrap';
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RestrictedRoutes from "./hoc/RestrictedRoutes";
import PrivateRoutes from "./hoc/PrivateRoutes";
import { routesArray } from "./app/routesArray";
import PageLoader from "./components/common/PageLoader";

function App() {
  const navRef = React.useRef(null);
  const addShow = React.useRef(null);
  const onAddClick = (e) => {
    navRef.current.classList.add("show");
    addShow.current.classList.add("show");
  };
  const onRemoveClick = (e) => {
    navRef.current.classList.remove("show");
    addShow.current.classList.remove("show");
  };
  return (
    <Router>
      <div className="App" ref={navRef}> 
      <Button onClick={onAddClick} className="togglebtn"><i class="fa-solid fa-bars"></i></Button>
      <Button ref={addShow} onClick={onRemoveClick} className="togglebtn1"><i class="fa-solid fa-xmark"></i></Button>
       
        <Routes>
          {routesArray.map((route, index) => {
            let ComponentName = route.element;
            if (route.isPrivateRoute) {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <React.Suspense fallback={<PageLoader />}>
                      <PrivateRoutes>
                        <ComponentName />
                      </PrivateRoutes>
                    </React.Suspense>
                  }
                />
              );
            }

            if (route.isRestrictedRoute) {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <React.Suspense fallback={<PageLoader />}>
                      <RestrictedRoutes>
                        <ComponentName />
                      </RestrictedRoutes>
                    </React.Suspense>
                  }
                />
              );
            }

            if (route.isOpenRoute) {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <React.Suspense fallback={<PageLoader />}>
                      <ComponentName />
                    </React.Suspense>
                  }
                />
              );
            }
            return null;
          })}          
        </Routes>
      </div>
    </Router>
  );
}

export default App;
